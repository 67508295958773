import React, { useEffect } from "react";

const ExternalScriptsLoader = () => {
  useEffect(() => {
    console.log("Component mounted");

    // Function to load the Ringba script
    const loadRingba = () => {
      console.log("Loading Ringba script");
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src = "//b-js.ringba.com/CA3bdf875420e7444f86d1091d646f2a58";
      document.body.append(script);
      console.log("Ringba script added to the document");
    };

    // Function to load the Universal script
    const loadScript = () => {
      console.log("Universal script loaded");
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://track.imgtrx3.com/uniclick.js?attribution=lastpaid&cookiedomain=firstrateselfstorage.com&cookieduration=30&defaultcampaignid=6353ed8fa3c013000136d49d&regviewonce=false";
      document.body.append(script);
      console.log("Universal script added to the document");
    };

    // Function to check for a specific cookie
    const checkCookie = () => {
      console.log("Checking Cookie");
      const myInterval = setInterval(() => {
        const myCookie = sessionStorage.getItem("rtkclickid");
        if (myCookie && window._rgba_tags) {
          console.log("COOKIE RECEIVED", myCookie);
          (window._rgba_tags = window._rgba_tags || []).push({
            clickID: myCookie,
          });
          clearInterval(myInterval);
          console.log("Cookie found and pushed to _rgba_tags");
        } else {
          console.log("Cookie is not there");
        }
      }, 2000);
    };

    // DOMContentLoaded event equivalent
    const onDOMContentLoaded = () => {
      console.log("DOMContentLoaded event");
      window._rgba_tags = undefined;
      loadRingba();
      loadScript();
      checkCookie();
    };

    // Trigger the DOMContentLoaded logic
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    } else {
      onDOMContentLoaded();
    }

    // Cleanup function
    return () => {
      console.log("Component unmounted, cleaning up");
      document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
    };
  }, []);

  return null; // No UI component needed, only script loading
};

export default ExternalScriptsLoader;
