import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_rd_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import BackgroundImage from "../assets/images/lp3.webp";
import Image1 from '../assets/images/woman-looking-picture-while-putting-belongings-away.webp'
import WhyChooseUs from "../components/WhyChooseUs";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_rd_lps.js");
  const HeroContent = {
    Heading: `Simplify Your Storage Needs`,
    Para: `Get Matched with <br />  Top-Rated <br /> Self-Storage Facilities`,
    Image: BackgroundImage,
  };

  const WhychooseUs = {
    Heading: `Maximize Efficiency  Minimize Clutter
Get a Free Self-Storage Quote Today`,
    Image: Image1,
  };
  const PhoneNumber = {
    PhoneNo: "(844) 557-0918",
  };

  return (
    <div>
       <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection PhoneNumber={PhoneNumber} HeroContent={HeroContent} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <WhyChooseUs WhychooseUs={WhychooseUs} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
