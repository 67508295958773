import React, { useState, useEffect } from "react";
// import Image from "../assets/images/couple-painting-their-new-home-after-moving-together.webp";
import slide1 from '../assets/images/slider/full-shot-man-talking-phone.webp';
import slide2 from '../assets/images/slider/messy-interior-full-clothing.webp';
import slide3 from '../assets/images/slider/spring-wardrobe-switch-still-life.webp';
import Image from '../assets/images/slider/wardrobe-renovation-winter.webp';

const About = () => {
  const [current, setCurrent] = useState(0);
  const images = [slide1, slide2, slide3];

  useEffect(() => {
    const imageSlides = document.getElementsByClassName("slide-image");
    const bubbles = document.getElementsByClassName("bubble-outer");
    bubbles[current].style.borderColor = "white";
  }, [current]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 8000); // Change image every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [current]);

  const nextImage = () => {
    const newIndex = (current === images.length - 1 ? 0 : current + 1);
    setCurrent(newIndex);
  };

  const prevImage = () => {
    const newIndex = (current === 0 ? images.length - 1 : current - 1);
    setCurrent(newIndex);
  };

  const jumpImage = (e) => {
    const jumpIndex = parseInt(e.target.id);
    if (jumpIndex === current) return;
    setCurrent(jumpIndex);
  };

  return (
    <div className="AboutUs">
      <div className="aboutContainer">
        <div className="aboutContent">
          <h2>Why You Need A Storage Unit?</h2>
          <span>American homes are shrinking, but your stuff isn't.</span>
          <p>
          Downsizing doesn't mean giving up your belongings. From seasonal decorations to treasured keepsakes, everyone needs extra storage. <br />
          We make finding the perfect unit simple by connecting you with a network of secure, affordable facilities offering various sizes and 24/7 access, all at competitive prices.
          </p>
          <div className="btn-group">
            <a href="tel:123456789">CALL NOW: (844) 557-0918</a>
          </div>
        </div>
        <div className="aboutImages">
          <div className="gallery-container">
            <span className="button-prev" onClick={prevImage}>{"<"}</span>
            <span className="button-next" onClick={nextImage}>{">"}</span>
            <div className="gallery-track">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`slide-image ${index === current ? 'leftIn' : 'leftOut'}`}
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
              ))}
            </div>
            <div className="gallery-footer">
              {images.map((_, index) => (
                <div
                  key={index}
                  className="bubble-outer"
                  onClick={jumpImage}
                  id={index}
                >
                  {/* <div className="bubble-inner" id={index}></div> */}
                </div>
              ))}
            </div>
          </div>
          <img className="abs-gallery" src={Image} alt="" />
        </div>
      </div>
    </div>
  );
}

export default About;
