import React from "react";
import BackgroundImage from "../assets/images/home-hero.webp";

const HeroSection = ({ HeroContent, PhoneNumber }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <main>
      <div className="container over" style={{ position: "relative", borderRadius: "0px", color: "white", width: "100%" }}>
        <img
          src={HeroContent.Image}
          alt="Background"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 1,
            borderRadius: "0px",
          }}
        />
        <div
          className="image-container"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            zIndex: 2,
          }}
        >
          {/* Add any content or additional elements here */}
          <div className="cttt" style={{ position: "relative", zIndex: 3 }}>
            <div className="HeroContent">
              <h2 dangerouslySetInnerHTML={{ __html: HeroContent.Heading }} />
              <p dangerouslySetInnerHTML={{ __html: HeroContent.Para }} />
            </div>
          </div>
          <div className="btnnnn" style={{ position: "relative", zIndex: 3 }}>
            <div className="btn-group">
              <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
