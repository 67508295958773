import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_rd_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import BackgroundImage from "../assets/images/lp1.webp";
import WhyChooseUs from "../components/WhyChooseUs";
import Image1 from '../assets/images/warehouse-managers-checking-orders-list-clipboard-taking-parcel-from-shelf-storehouse-workers-searching-product-storage-room-rack-preparing-package-dispatching.webp';
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_rd_lps.js");
  const HeroContent = {
    Heading: `Your Trusted Storage Solution`,
    Para: "Get Matched with Secure, Affordable Storage Facilities",
    Image: BackgroundImage,
  };

  const WhychooseUs = {
    Heading: "Discover Secure and Budget-Friendly Storage Solutions",
    Image: Image1,
  };
  const PhoneNumber = {
    PhoneNo: "(844) 557-0918",
  };

  return (
    <div>
       <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection PhoneNumber={PhoneNumber} HeroContent={HeroContent} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <WhyChooseUs WhychooseUs={WhychooseUs} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
