import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function TermsCondition() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="190"
        class="elementor elementor-190"
      >
        
        <section
          data-id="64c5e4a2"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          class="elementor-section elementor-top-section elementor-element elementor-element-64c5e4a2 elementor-section-boxed elementor-section-height-default lazyloaded"
        >
          
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-256834c8"
              data-id="256834c8"
              data-element_type="column"
            >
              
              <div class="elementor-widget-wrap elementor-element-populated nb">
                
                <div
                  class="elementor-element elementor-element-435bdd46 elementor-widget elementor-widget-heading"
                  data-id="435bdd46"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  
                  <div class="elementor-widget-container">
                    
                    <h1 class="elementor-heading-title elementor-size-default">
                      Terms and Conditions
                    </h1>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-5ec56121 elementor-widget elementor-widget-text-editor"
                  data-id="5ec56121"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  
                  <div class="elementor-widget-container">
                    
                    <p>
                      These Terms and Conditions constitute a legally binding
                      agreement made between you, whether personally or on
                      behalf of an entity (“you”) and First Rate Self Storage
                      (“we,” “us,” or “our”), concerning your access to and use
                      of the <span></span>

                      <a
                       
                        href="https://firstrateselfstorage.com/"
                      >
                        <strong> FirstRateSelfStorage.com</strong>
                      </a>
                      &nbsp;website as well as any other media form, media
                      channel, mobile website or mobile application related,
                      linked, or otherwise connected to it (collectively, the
                      “Site”).
                    </p>
                    <p>
                      You agree that by accessing the Site, you have read,
                      understood, and agree to be bound by all of these Terms
                      and Conditions. If you do not agree with all of these
                      Terms and Conditions, you are expressly prohibited from
                      using the Site and must discontinue use immediately.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-6b3d7e67 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="6b3d7e67"
          data-element_type="section"
        >
          
          <div class="elementor-container elementor-column-gap-default">
            
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-71ecee28"
              data-id="71ecee28"
              data-element_type="column"
            >
              
              <div class="elementor-widget-wrap elementor-element-populated">
                
                <div
                  class="elementor-element elementor-element-70231c13 elementor-widget elementor-widget-text-editor"
                  data-id="70231c13"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  
                  <div class="elementor-widget-container">
                    
                    <h4>Intellectual Property</h4>
                    <p>
                      The Site may contain (or you may be sent via the Site)
                      links to other websites (“Third-Party Websites”) as well
                      as articles, photographs, text, graphics, pictures,
                      designs, music, sound, video, information, applications,
                      software, and other content or items belonging to or
                      originating from third parties (“Third-Party Content”).
                    </p>
                    <p class="nitro-offscreen">
                      <span>
                        Such Third-Party Websites and Third-Party Content are
                        not investigated, monitored, or checked for accuracy,
                        appropriateness, or completeness by us. We are not
                        responsible for any Third-Party Websites accessed
                        through the Site or any Third-Party Content posted on,
                        available through, or installed from the Site, including
                        the content, accuracy, offensiveness, opinions,
                        reliability, privacy practices, or other policies of or
                        contained in the Third-Party Websites or the Third-Party
                        Content.
                      </span>
                      <br />
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        Inclusion of, linking to, or permitting the use or
                        installation of any Third-Party Websites or any
                        Third-Party Content does not imply approval or
                        endorsement thereof. If you decide to leave the Site and
                        access the Third-Party Websites or to use or install any
                        Third-Party Content, you do so at your own risk, and you
                        should be aware these Terms and Conditions no longer
                        govern.
                      </span>
                      <br />
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        We strongly advise you to read the Third-Party Website
                        terms and conditions and the privacy policies of any
                        site or services you visit or use.
                      </span>
                      <br />
                    </p>
                    <h4  class="nitro-offscreen">
                      Indemnification
                    </h4>
                    <p class="nitro-offscreen">
                      You agree to defend, indemnify, and hold First Rate Self
                      Storage harmless, including our subsidiaries, affiliates,
                      and all of our respective officers, agents, partners, and
                      employees, from and against any loss, damage, liability,
                      claim, or demand, including reasonable attorneys’ fees and
                      expenses, made by any third party due to or arising out
                      of:
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        (1) use of the Site
                      </span>
                    </p>
                    <p class="nitro-offscreen">
                      (2) breach of these Terms and Conditions
                    </p>
                    <p class="nitro-offscreen">
                      (3) any breach of your representations and warranties
                      outlined in these Terms and Conditions
                    </p>
                    <p class="nitro-offscreen">
                      (4) your violation of the rights of a third party,
                      including but not limited to intellectual property rights
                    </p>
                    <p class="nitro-offscreen">
                      (5) any overt harmful act toward any other user of the
                      Site with whom you connected via the Site
                    </p>
                    <h4  class="nitro-offscreen">
                      Limitation of Liability
                    </h4>
                    <p class="nitro-offscreen">
                      In no event will we or our directors, employees, or agents
                      be liable to you or any third party for any direct,
                      indirect, consequential, exemplary, incidental, special,
                      or punitive damages, including lost profit, lost revenue,
                      loss of data, or other damages arising from your use of
                      the site, even if we have been advised of the possibility
                      of such damages.
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        Notwithstanding anything to the contrary contained
                        herein, our liability to you for any cause whatsoever
                        and regardless of the form of the action will at all
                        times be limited to the lesser of the amount paid, if
                        any, by you to us during the ONE (1) month period before
                        any cause of action arising or US$100. State laws may
                        not allow limitations on implied warranties or the
                        exclusion or limitation of certain damages.
                      </span>
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        If certain State laws apply to you, some or all
                        disclaimers found within these terms and conditions or
                        limitations may not apply to you, and you may have
                        additional rights.
                      </span>
                    </p>
                    <h4  class="nitro-offscreen">
                      Exclusions
                    </h4>
                    <p class="nitro-offscreen">
                      While the limitations above may not apply to you, some
                      countries do not allow exclusions or limitations of
                      liability for consequential damages, so please check with
                      your jurisdiction before purchasing this insurance.
                    </p>
                    <h4  class="nitro-offscreen">
                      Governing Law
                    </h4>
                    <p class="nitro-offscreen">
                      These Terms and Conditions and your use of the Site are
                      governed by and construed following the laws of the State
                      of Florida applicable to agreements made and to be
                      entirely performed within the State of Florida, without
                      regard to its conflict of law principles.
                    </p>
                    <h4  class="nitro-offscreen">
                      Changes
                    </h4>
                    <p class="nitro-offscreen">
                      Supplemental terms and conditions or documents posted on
                      the Site from time to time are hereby expressly
                      incorporated herein by reference. In our sole discretion,
                      we reserve the right to make changes or modifications to
                      these Terms and Conditions at any time and for any reason.
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        We will alert you about any changes by updating the
                        “Last Updated” date of these Terms and Conditions, and
                        you waive any right to receive specific notice of each
                        such change.
                      </span>
                    </p>
                    <p class="nitro-offscreen">
                      <span >
                        You must periodically review these Terms and Conditions
                        to stay informed of updates. You will be subject to and
                        will be deemed to have been made aware of and accepted
                        the changes in any revised Terms and Conditions by your
                        continued use of the Site after the date such revised
                        Terms and Conditions are posted.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
