import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import About from "./components/About";
import BackgroundImage from "./assets/images/home-hero.webp";
import Image1 from './assets/images/woman-looking-picture-while-putting-belongings-away.webp'
export default function landingPage() {
  const HeroContent = {
    Heading: `Declutter Your Life <br />
            Find Your Perfect Storage Unit`,
    Para: "Find Secure and Affordable Storage With Our Referral Service",
    Image: BackgroundImage,
  };
  const PhoneNumber = {
    PhoneNo: "(844) 557-0918",
  };
  const WhychooseUs = {
    Heading: "Get Matched With Your Ideal Storage Unit Today",
    Image: Image1,
  };
  return (
    <div className="lp">
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection PhoneNumber={PhoneNumber} HeroContent={HeroContent} />
      <About />

      <HowItWorks PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber} />
      <Contact PhoneNumber={PhoneNumber} />
      <WhyChooseUs WhychooseUs={WhychooseUs} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
