import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import icon1 from "../assets/images/why choose us icons/Pre-vetted Facilities.png";
import cPrice from "../assets/images/why choose us icons/competetive price blue.png";
import preVetted from "../assets/images/why choose us icons/Pre-vetted Facilities blue.png";
import varOption from '../assets/images/why choose us icons/Variety of Options blue.png';
export default function Services({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Services">
      <h2>WHY CHOOSE US?</h2>
      <span>Skip the Search, Find the Perfect Fit</span>
      <p className="p">We connect you with a network of top-rated facilities in your area, saving you precious time and frustration.</p>
      <div className="SerCards">
        <div className="SerCard">
          <div class="sx-icon-box-wraper  icon-count-2-outer">
            <div class="icon-count-2 bg-white">
              <span class="icon-count-number">01</span>
              <div class="icon-xl inline-icon m-b5 scale-in-center">
                <span class="icon-cell">
                  <i class="flaticon-sketch"><img src={preVetted} alt="" /></i>
                </span>
              </div>
              <div class="icon-content">
                <h4 class="sx-tilte">Pre-vetted Facilities</h4>
                <p>
                We partner with reputable self-storage providers known for cleanliness, security, and exceptional customer service
                </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="SerCard">
          <div class="sx-icon-box-wraper  icon-count-2-outer">
            <div class="icon-count-2 bg-white">
              <span class="icon-count-number">02</span>
              <div class="icon-xl inline-icon m-b5 scale-in-center">
                <span class="icon-cell">
                  <i class="flaticon-sketch"><img src={varOption} alt="" /></i>
                </span>
              </div>
              <div class="icon-content">
                <h4 class="sx-tilte">Variety of Options</h4>
                <p>
                Find the perfect unit size, climate control features, and convenient access hours to suit your specific needs.
                </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="SerCard">
          <div class="sx-icon-box-wraper  icon-count-2-outer">
            <div class="icon-count-2 bg-white">
              <span class="icon-count-number">03</span>
              <div class="icon-xl inline-icon m-b5 scale-in-center">
                <span class="icon-cell">
                  <i class="flaticon-sketch"><img src={cPrice} alt="" /></i>
                </span>
              </div>
              <div class="icon-content">
                <h4 class="sx-tilte">Competitive Prices</h4>
                <p>
                Access exclusive deals and discounts not available to the public
                </p>
                
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="btn-group">
      <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
    </div>
  );
}
