import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import vStor from "../assets/images/Vehicle Storage.webp";
import bStor from "../assets/images/business storage.webp";
import hStor from "../assets/images/Home Storage.webp";

export default function HowItWorks({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="HowWorks">
      <h2>COMMON TYPES OF SELF STORAGE</h2>

      <div className="workCards">
        <div className="preworkCard">
          <div className="figcaption-number text-center sx-text-primary animate-in-to-top-content">
            <span>01</span>
            <div className="span"></div>
          </div>
          <div className="figcaption  text-center p-a20">
            <h4 className="m-a0">Home Storage</h4>
          </div>

          <div className="workCard">
            <img src={hStor} alt="Background" />
            <div className="overlay"></div>
            <div className="above_content">
            Declutter your living space! Find secure units for seasonal items, furniture, or anything that takes up valuable room at home.
            </div>
          </div>
        </div>
        <div className="preworkCard">
          <div className="figcaption-number text-center sx-text-primary animate-in-to-top-content">
            <span>02</span>
            <div className="span"></div>
          </div>
          <div className="figcaption  text-center p-a20">
            <h4 className="m-a0">Business Storage</h4>
          </div>
          <div className="workCard">
            <img src={bStor} alt="Background" />
            <div className="overlay"></div>
            <div className="above_content">
            Maximize your workspace! We'll connect you with facilities offering flexible, affordable storage solutions for your business needs.
            </div>
          </div>
        </div>
        <div className="preworkCard">
          <div className="figcaption-number text-center sx-text-primary animate-in-to-top-content">
            <span>03</span>
            <div className="span"></div>
          </div>
          <div className="figcaption  text-center p-a20">
            <h4 className="m-a0">Vehicle Storage</h4>
          </div>
          <div className="workCard">
            <img src={vStor} alt="Background" />
            <div className="overlay"></div>
            <div className="above_content">
            Need a safe haven for your car, motorcycle, or RV? We can match you with secure vehicle storage options, short-term or long-term.
            </div>
          </div>
        </div>
      </div>
      <div className="btn-group">
      <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
      </div>
    </div>
  );
}
