import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Image1 from '../assets/images/close-up-warehouse-view.webp'
export default function WhyChooseUs({ PhoneNumber , WhychooseUs}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="WhyChooseUs">
    <div className="ct-flex">
      <div className="ct-img">
        <img src={WhychooseUs.Image} alt="" />
      </div>
      <div className="ct-ct-cnt">
        <h2>{WhychooseUs.Heading}</h2>
      </div>
      <div className="ct-btn">
      <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
      </div>
    </div>
 
  
  
   </div>
  );
}
